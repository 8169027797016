import React, { useEffect, useState } from "react";
import "./sysInfo.scss";
import { matchVesion } from "./sysfnc"; 

const BrowserUpgrade = () => {
  const [currentBrowser, setCurrentBrowser] = useState("");

  useEffect(() => {
    const result = matchVesion();
    console.log(result, 9595);

    if (result && result.browser) {
      // 确保result存在并且有browser属性
      setCurrentBrowser(result.browser);
    }
  }, []);
  const handleImageClick = (url: any) => {
    window.open(url, "_blank");
  };

  return (
    <div className="body-box">
      <div className="contain">
        <div className="image">
          <img
            src="https://assets.wangxiao.net/images/toPathImg/noNetwork.png"
            alt=""
            style={{ marginBottom: "40px" }}
          />
        </div>
        <div className="message">
          <p style={{ fontSize: "24px", fontWeight: "600" }}>
            您的浏览器版本过低
          </p>
          <p style={{ color: "#808695", fontSize: "14px", lineHeight:"22px" }}>
            您当前使用的浏览器版本过低，已无法正常访问此页面。
            <br />
            为了确保您获得最佳的浏览体验和网站功能，我们建议您使用最新版本的浏览器访问本网站。
          </p>
        </div>
        <div className="br-thread">
          <div className="br-thread-line">升级您的浏览器</div>
        </div>
        <div className="browsers">
          {currentBrowser === "chrome" && (
            <div className="browser">
              <img
                src="https://assets.wangxiao.net/images/toPathImg/chrome.png"
                alt="Chrome"
                className="browser-img"
                onClick={() =>
                  handleImageClick("https://www.google.cn/chrome/")
                }
              />
            </div>
          )}
          {currentBrowser === "Edge" && (
            <div className="browser">
              <img
                src="https://assets.wangxiao.net/images/toPathImg/edge.png"
                alt="Edge"
                className="browser-img"
                onClick={() =>
                  handleImageClick("https://www.microsoft.com/zh-cn/edge/download?form=MA13FJ")
                }
              />
            </div>
          )}
          {currentBrowser === "Firefox" && (
            <div className="browser">
              <img
                src="https://assets.wangxiao.net/images/toPathImg/firefox.png"
                alt="Firefox"
                className="browser-img"
                onClick={() =>
                  handleImageClick("https://www.mozilla.org/en-US/firefox/new/")
                }
              />
            </div>
          )}
          {currentBrowser === "Safari" && (
            <div className="browser">
              <img
                src="https://assets.wangxiao.net/images/toPathImg/safari.png"
                alt="Safari"
                className="browser-img"
                onClick={() =>
                  handleImageClick("https://support.apple.com/zh-cn/102665")
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrowserUpgrade;