import React, { useEffect, useState } from "react";
import Router from "./router/index";
import channelStore from "@/store/channel";
import { getChannelId, getTenantInfo } from "./apis/settlement";
import SysCheck from "./pages/SysInfo/sysCheck";

function App() {
  const [logoUrl, setLogoUrl] = useState(""); // logo
  const [appName, setAppName] = useState("");
  const [baiduStatistics, setBaiduStatistics] = useState(""); // 百度统计代码
  const fetchLogo = async () => {
    try {
      const response = await getChannelId();
      console.log("APP组件调用的response", response);
      if (response && response.appLogo && response.companyName) {
        setLogoUrl(response.appLogo);
        setAppName(response.companyName);
      }
    } catch (error) {
      console.error("调用失败", error);
    }
  };

  // 获取渠道信息
  const getChannelInfo = async () => {
    const response = await getTenantInfo();
    channelStore.setChannelInfo(response);
  };
  // 获取百度统计脚本并插入到页面中
  const insertAndExecuteScript = async () => {
    try {
      const res = await getChannelId();

      const  baiduStatistics = res.baiduStatistics;
      setBaiduStatistics(baiduStatistics);
      console.log(baiduStatistics, "百度统计代码");
      
      const script = document.createElement("script");

      // 正则表达式，用于匹配<script>标签中的内容（不包括标签本身）
      const regex = /<script\b[>]*>([\s\S]*?)<\/script>/;
      const scriptString = baiduStatistics;
      const match = scriptString.match(regex);

      if (match) {
        const content = match[1]; // 第一个捕获组中的内容即为<script>标签内的内容
        script.textContent = content;
        document.head.appendChild(script);
        console.log(content, "content---");
      } else {
        console.log("No match found.");
      }
    } catch (error) {
      console.error("获取百度统计代码失败", error);
    }
  };

  useEffect(() => {
    fetchLogo();
    getChannelInfo();
  }, []);

  // 监听 appName 和 logoUrl 的变化来更新浏览器的标题和图标
  useEffect(() => {
    document.title = appName; // 更新页面标题

    // 更新 favicon
    let link = document.querySelector(
      "link[rel*='icon']"
    ) as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.type = "image/x-icon";
    link.href = logoUrl;
  }, [appName, logoUrl]);
  useEffect(() => {
    insertAndExecuteScript(); // 插入百度统计代码
  }, []);
  return (
    <div>
      <SysCheck />
      <Router />
    </div>
  );
}

export default App;
