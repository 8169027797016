/*
 * @Author: liuweixing
 * @Date: 2024-05-13 18:32:39
 * @Description:
 */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LiveList from "@/components/LiveList";
import Coupon from "@/components/Coupon";
import LiveSingle from "@/components/LiveSingle";
import { requestShopDecorateTemplate } from "@/apis/common";
import { WEB_PAGE_CODE } from "@/config/const";
import TopNav from "../Home/TopNav";
import Recommend from "../Home/Recommend";
import Information from "../Home/Information";
import Advertisement from "@/components/Advertisement";
import RichText from "@/components/RichText";
import Footer from "@/components/Footer";
import userStore from "@/store/user";
import GoodsShow from "@/components/GoodsShow"; // 商品展示组件
import StudyGuide from "@/components/StudyGuide";
import {
  formatInfoData,
  formatGoodsData,
  formatCouponData,
  formatLiveListData,
  formatLiveIngData,
  formatAdvertisement,
  formatEditor,
  formatFooter,
  formatStudyGuide,
  formatCategoryNavigation,
  formatHeader,
} from "@/utils/shopDecorate";
import Header from "@/components/Header";

function WebPage() {
  const params: any = useParams();
  const { id = "" } = params;
  // 获取店铺装修数据
  const [webPageTemplate, setwebPageTemplate] = useState([]);
  const [bgImage, setBgImage] = useState("");
  const [bgColor, setBgColor] = useState("");
  const getShopDecorateTemplate = async () => {
    const { pageJson = "{}", areaJson = "{}" } =
      await requestShopDecorateTemplate({
        pageCode: WEB_PAGE_CODE,
        id,
      });
    const areaJson1 = JSON.parse(areaJson);
    areaJson1?.style?.forEach((item: any) => {
      if (item.attr === "background-image") {
        setBgImage(item.val);
      }
      if (item.attr === "background-color") {
        setBgColor(item.val);
      }
    });
    setwebPageTemplate(JSON.parse(pageJson));
    // const { areaJson = "{}", pageJson = "{}"} = result;
    // 统一在src/utils/shopDecorate.ts格式化组件数据
    return;
  };
  const renderWebPage = (data: any, index: number) => {
    switch (data.type) {
      case "pc-goods-show":
        return <GoodsShow data={data} />;
      case "pc-study-guide":
        const studyGuideData = formatStudyGuide(data);
        return <StudyGuide data={studyGuideData} key={index} />;
      //页眉
      case "header-comp":
        const formatHeaderData = formatHeader(data);
        return <Header data={formatHeaderData} key={index} />;
      //分类导航
      case "classify-navigation":
        const formatCategoryNavigationData = formatCategoryNavigation(data);
        return <TopNav data={formatCategoryNavigationData} key={index} />;
      //广告位
      case "pc-advertisement":
        const formatAdvertisementData = formatAdvertisement(data);
        return <Advertisement data={formatAdvertisementData} key={index} />;
      //商品组件
      case "goods-list":
        const goodsData = formatGoodsData(data);
        return <Recommend data={goodsData} key={index} />;
      //资讯
      case "info-list":
        const formatData = formatInfoData(data);
        return <Information data={formatData} key={index} />;
      //优惠券
      case "coupon-comp-pc":
        const {
          title,
          showTitle,
          showMore,
          showNum,
          ids,
          hideLossEfficacy,
          layout,
          customStyle,
        } = formatCouponData(data);
        return (
          <Coupon
            title={title}
            showTitle={showTitle}
            showMore={showMore}
            showNum={showNum}
            ids={ids}
            hideLossEfficacy={hideLossEfficacy}
            layout={layout}
            customStyle={customStyle}
            key={index}
          />
        );
      //富文本
      case "editor-text":
        const formatEditordata = formatEditor(data);
        return <RichText data={formatEditordata} key={index} />;
      //直播列表
      case "live-list-pc":
        const liveData = formatLiveListData(data);
        return <LiveList {...liveData} key={index} />;
      //直播
      case "live-single-pc":
        const liveIng = formatLiveIngData(data);
        return <LiveSingle {...liveIng} key={index} />;
      //页尾
      case "footer-comp":
        const formatFooterData = formatFooter(data);
        userStore.setFooterComp(formatFooterData);
        return <Footer data={formatFooterData} key={index} />;
    }
  };

  useEffect(() => {
    getShopDecorateTemplate();
  }, []);
  return (
    <div
      style={{
        backgroundColor: bgColor,
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        height: "100vh",
      }}
    >
      {webPageTemplate.map((item, index) => {
        return renderWebPage(item, index);
      })}
    </div>
  );
}

export default WebPage;
