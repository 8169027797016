import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import {
  fetchGetCourseProjectList,
  getMyCommidityCourse,
  getMySkuLessons,
} from "@/apis/course";
import { Course, CourseResponse, Tab } from "../types";
import CourseListModal from "@/components/CourseListModal";
import "../css/MyCourse.scss";
import userStore from "@/store/user";

const MyCourse: React.FC = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState<Course[]>([]);
  const [tabs, setTabs] = useState<Tab[]>([{ key: "all", value: "全部" }]);
  const [activeTab, setActiveTab] = useState<string>("all");
  // const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false) // 手机H5学习或购买弹框
  const [courseListModal, setCourseListModal] = useState(false); // 课程列表弹窗
  const [courseList, setCourseList] = useState<Course[]>([]); // 课程列表数据
  const [commodityId, setCommodityId] = useState<string>(""); // 商品id
  const [supplierId, setSupplierId] = useState<string>(""); // 商品id
  const changeTab = (id: any) => {
    setActiveTab(id);
  };
  const gotoStudy = (skuId: string, commodityId: string,supplierId:string) => {
    // setQrCodeModalOpen(true)
    setCourseListModal(true);
    getCourseList(skuId);
    setCommodityId(commodityId);
    setSupplierId(supplierId || '' )
    userStore.setSupplierId(supplierId ||'');
  };

  const CourseCard: React.FC<{ course: Course }> = ({ course }) => {
    return (
      <div className="course-card">
        <img className="course-img" alt="course-img" src={course.thumbnail} />
        <div className="course-info">
          <div className="course-title">{course.commodityName}</div>
          {/* <div className="course-use-time">{course.useTime}</div> */}
          <div className="course-use-time">{course.skuName}</div>
          {/* <button className="course-button" onClick={gotoStudy}>
            开始学习
          </button> */}
          <button
            className="course-button"
            onClick={() => gotoStudy(course.skuId, course.commodityId,course.supplierId)}
          >
            开始学习
          </button>
        </div>
      </div>
    );
  };
  useEffect(() => {
    const getCourseProjects = async () => {
      try {
        const courseProjects = await fetchGetCourseProjectList();
        const projectsTabs: Tab[] = courseProjects.map((project) => ({
          key: project.k,
          value: project.v,
        }));
        setTabs([{ key: "all", value: "全部" }, ...projectsTabs]);
      } catch (error) {
        console.error("Error fetching course project list:", error);
      }
    };

    getCourseProjects();
  }, []);

  useEffect(() => {
    const getCourseListByTab = async () => {
      try {
        const id = activeTab === "all" ? "" : activeTab;
        // const response: CourseResponse = await fetchGetCourseList(id);
        const response: CourseResponse = await getMyCommidityCourse(id);
        console.log("response.data-----", response.data);
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching course list:", error);
      }
    };

    getCourseListByTab();
  }, [activeTab]);
  // 获取每个商品下面的课程列表
  useEffect(() => {}, [activeTab]);

  const handleCloseQrCode = () => {
    // setQrCodeModalOpen(false)
    setCourseListModal(false);
  };
  function handleToHome() {
    navigate("/");
  }

  // 根据商品id查询课程
  async function getCourseList(skuId: string) {
    const response = await getMySkuLessons(skuId);
    setCourseList(response);
    console.log("我是商品id查询课程返回的数据------", response);
  }
  return (
    <div className="my-course-container">
      <div className="tab-header">
        <div className="course-tab-list">
          <Tabs
            tabPosition={"top"}
            indicatorSize={(origin) => origin - 16}
            style={{ height: 220, maxWidth: 910 }}
            items={tabs.map((item: any) => {
              return {
                label: `${item.value}`,
                key: `${item.key}`,
              };
            })}
            onChange={changeTab}
          />
        </div>
        {/* {tabs.map((tab) => (
            <div
            key={tab.key}
            className={`tab-item ${activeTab === tab.key ? "active" : ""}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.value}
            </div>
        ))} */}
      </div>

      {courses && courses.length > 0 ? (
        <div className="course-list">
          {courses.map((course, index) => (
            <CourseCard key={index} course={course} />
          ))}
        </div>
      ) : (
        <div className="course-empty">
          <img src={require("../images/course-empty.png")} alt="course-empty" />
          <div className="course-emptyText">您还未购买课程，点击去看看~</div>
          <button onClick={handleToHome}>去购买课程</button>
        </div>
      )}
      {/* <QrcodeModal
        isShow={qrCodeModalOpen}
        type={"study"}
        onCloseQrCode={handleCloseQrCode}
      /> */}
      <CourseListModal
        isShow={courseListModal}
        onCloseQrCode={handleCloseQrCode}
        courseList={courseList}
        commodityId={commodityId}
        supplierId={supplierId}
      />
    </div>
  );
};

export default MyCourse;
