/* eslint-disable  */
import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://assets.wangxiao.net/pdf/pdf.worker.js`;
const options = {
  cMapUrl: `https://assets.wangxiao.net/pdf/cmaps/`,
};
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "../css/videoPdf.scss";
function PdfPreview(props:any) {
    console.log(props,'预览参数')
    const {unitDetail} = props
    const [file, setFile] = useState(unitDetail.material);
    const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = (e:any)=>{
      if(unitDetail.isPurchase == 'false'){
        setNumPages(unitDetail.limit)
      }else{
        setNumPages(e.numPages);
      }
    }
    useEffect(() => {
      setFile(unitDetail.material)
    }, [unitDetail]);
  return (
    <div className="video-pdf-box">
      <Document
        options={options}
        file={file}
        className="document-pdf"
        onLoadSuccess={onDocumentLoadSuccess}
      >
          {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                className="page-pdf"
                pageNumber={index + 1}
                renderAnnotationLayer={true}
                renderTextLayer={true}
              />
            ))
          }
      </Document>
    </div>
  );
}

export default PdfPreview;