const lastChrome = { browser: 'chrome', version: '92.0.4515.107' }; //92.0.4515.107
const lastEdge = { browser: 'edge', version: '118.0.2088.69' };
const last360 = { browser: '360se', version: '14.1.1278.0' };
const last360x = { browser: '360', version: '21.0.1050.0' };
const lastMacChrome = { browser: 'chrome', version: '108.0.5359.95' };
const lastMacEdge = { browser: 'edge', version: '119.0.2146.0' };
const lastMacSafari = { browser: 'safari', version: '15.6.1' };
const lastMac360 = { browser: '360', version: '14.5.1006.0' };
export function matchVesion() {
  const userAgent = navigator.userAgent;
  const rMsie = /(msie\s|trident.*rv:)([\w.]+)/;
  const rEdge = /(edg)\/([\w.]+)/;
  const r360 = /(360)\/([\w.]+)/;
  const r360se = /(360se)\/([\w.]+)/;
  const rFirefox = /(firefox)\/([\w.]+)/;
  const rOpera = /(opera).+version\/([\w.]+)/;
  const rChrome = /(chrome)\/([\w.]+)/;
  const rSafari = /version\/([\w.]+).*(safari)/;
  const ua = userAgent.toLowerCase();

  const match = rMsie.exec(ua);
  if (match !== null) {
    return { browser: 'IE', version: match[2] || '0' };
  }
  const rEmatch = rEdge.exec(ua);
  if (rEmatch !== null) {
    return { browser: 'Edge', version: rEmatch[2] || '0' };
  }
  const rFmatch = rFirefox.exec(ua);
  if (rFmatch !== null) {
    return { browser: rFmatch[1] || '', version: rFmatch[2] || '0' };
  }
  const rOmatch = rOpera.exec(ua);
  if (rOmatch !== null) {
    return { browser: rOmatch[1] || '', version: rOmatch[2] || '0' };
  }
  const rCmatch = rChrome.exec(ua);
  if (rCmatch !== null) {
    return { browser: rCmatch[1] || '', version: rCmatch[2] || '0' };
  }
  const rSmatch = rSafari.exec(ua);
  if (rSmatch !== null) {
    return { browser: rSmatch[2] || '', version: rSmatch[1] || '0' };
  }
  const r360match = r360.exec(ua);


  if (r360match !== null) {
    const r360seMatch = r360se.exec(ua);
    if (r360seMatch !== null) {
      return { browser: '360se', version: r360seMatch[2] || '0' };
    } else {
      return { browser: '360', version: r360match[2] || '0' };
    }
  }

  if (match !== null) {
    return { browser: '', version: '0' };
  }
}

const compareVersions = (version1:any, version2:any) => {
  // 分割版本号为子版本号列表，并将每个部分转换成数字
  console.log(version1, '与', version2, '版本比较');

  const v1Parts = version1.split('.').map(Number);
  const v2Parts = version2.split('.').map(Number);

  // version1是否只有第一位有值，其他位为0
  const hasOnlyFirstValue = v1Parts.slice(1).every((part:any) => part === 0);
  if (hasOnlyFirstValue) {
    console.log(v1Parts[0], '---', v2Parts[0], '版本只有首位时 :', v1Parts[0] - v2Parts[0]);
    return v1Parts[0] - v2Parts[0];
  }
  const maxLength = Math.max(v1Parts.length, v2Parts.length);
  // 逐位比较
  for (let i = 0; i < maxLength; i++) {
    // 如果一个版本号较短，则认为它后面的部分都是0
    const v1Part = i < v1Parts.length ? v1Parts[i] : 0;
    const v2Part = i < v2Parts.length ? v2Parts[i] : 0;
    if (v1Part > v2Part) return 1;
    if (v1Part < v2Part) return -1;
  }

  return 0; // equal
};

function getSystem() {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/MicroMessenger/i.test(userAgent)) return 'wechat';
  if (/iphone|ipod|android/i.test(userAgent)) return 'mobile';
  if (/windows nt/i.test(userAgent)) return 'windows';
  if (/macintosh|mac os x/i.test(userAgent)) return 'macOS';
  return 'other';
}
export function isLast() {
  const system: any = getSystem();
  const browserMatch: any = matchVesion();
  const userAgent = navigator.userAgent.toLowerCase();
  const isQQBrowser = userAgent.includes('qq'); //qq浏览器
  const isOperaBrowser = userAgent.includes('opr'); //opera浏览器
  const isSogouBrowser = userAgent.includes('se'); //搜狗浏览器
  const isQuark = userAgent.includes('quark');
  console.log(system, '系统');
  if (system == 'wechat' || system == 'mobile' || isQQBrowser || isOperaBrowser || isSogouBrowser || isQuark) {
    console.log('无需拦截');
    return false;
  }
  if (system == 'windows') {
    if (
      browserMatch.browser == 'chrome' &&
      compareVersions(browserMatch.version, lastChrome.version) < 0
    ) {
      return true;
    } else if (
      browserMatch.browser == 'Edge' &&
      compareVersions(browserMatch.version, lastEdge.version) < 0
    ) {
      return true;
    } else if (
      browserMatch.browser == '360' &&
      compareVersions(browserMatch.version, last360x.version) < 0
    ) {
      return true;
    } else if (
      browserMatch.browser == '360se' &&
      compareVersions(browserMatch.version, last360.version) < 0
    ) {
      return true;
    } else {
      return false;
    }
  } else if (system == 'macOS') {
    if (
      browserMatch.browser == 'chrome' &&
      compareVersions(browserMatch.version, lastMacChrome.version) < 0
    ) {
      return true;
    } else if (
      browserMatch.browser == 'Edge' &&
      compareVersions(browserMatch.version, lastMacEdge.version) < 0
    ) {
      return true;
    } else if (
      browserMatch.browser == '360' &&
      compareVersions(browserMatch.version, lastMac360.version) < 0
    ) {
      return true;
    } else if (
      browserMatch.browser == 'safari' &&
      compareVersions(browserMatch.version, lastMacSafari.version) < 0
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
