import React, { useEffect, useState } from "react";
import { Alert, Button } from "antd";
import { matchVesion } from "./sysfnc";
import "./sysCheck.scss";

const BrowserAlert = () => {
  const [isUnsupported, setIsUnsupported] = useState(false);

  useEffect(() => {
    const checkBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipod|android/i.test(userAgent); // 判断是否为移动端
      const browserMatch = matchVesion();
      const supportedBrowsers = ["chrome", "edge", "safari", "360"];
      // 检查当前浏览器是否不在支持列表中
      const is360 = userAgent.includes("360") || userAgent.includes("360se");
      // 判断浏览器
      const isOperaBrowser = userAgent.includes("opr"); //opera浏览器
      const isSogouBrowser = userAgent.includes("se"); //搜狗浏览器
      const isQQBrowser = userAgent.includes("qq"); //qq浏览器

      const is2435Browser =
        userAgent.includes("2345") || userAgent.includes("2345explorer"); //2435浏览器
      const isGreenBrowser = userAgent.includes("green"); //绿色浏览器
      const isBaiduBrowser = userAgent.includes("bidu"); //百度浏览器
      const isLiebaoBrowser = userAgent.includes("lb"); //猎豹浏览器
      const isMaxthonBrowser = userAgent.includes("maxthon"); //遨游浏览器
      const isUcBrowser = userAgent.includes("ubrowser"); //uc浏览器
      const isWorldWindowBrowser = userAgent.includes("world"); //世界之窗浏览器
      const isTaobaoBrowser = userAgent.includes("tao"); //淘宝浏览器
      const isQuark = userAgent.includes("quark");

      if (browserMatch) {
        if (
          (isGreenBrowser ||
            isQQBrowser ||
            isBaiduBrowser ||
            isLiebaoBrowser ||
            isMaxthonBrowser ||
            isUcBrowser ||
            isOperaBrowser ||
            isSogouBrowser ||
            isWorldWindowBrowser ||
            isTaobaoBrowser ||
            is360 ||
            is2435Browser ||
            isQuark) &&
          !sessionStorage.getItem("alertDismissed")
        ) {
          setIsUnsupported(true);
        } else if (
          !isMobile &&
          !supportedBrowsers.includes(browserMatch.browser.toLowerCase())
        ) {
          setIsUnsupported(true);
        }
      }
    };

    checkBrowser();
  }, []);

  const onClose = () => {
    setIsUnsupported(false);
    sessionStorage.setItem("alertDismissed", "true"); // 存储用户关闭提示的状态
  };

  return (
    <div>
      {isUnsupported && (
        <Alert
          message={
            <div style={{ fontSize: "12px", color: "#515a6e" }}>
              为了确保您获得最佳的浏览体验和网站功能，我们建议您使用最新版本的谷歌浏览器（Chrome）访问本网站。
            </div>
          }
          banner
          closable
          type="warning"
          showIcon
          onClose={onClose}
          action={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-8px",
              }}
            >
              <Button type="text" size="small" className="loadButton">
                <a
                  href="https://www.google.cn/chrome/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  点击下载
                </a>
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
};

export default BrowserAlert;
